import { useEffect, useState } from "react"
import api from "../../utils/api"
import { setBottomBarError } from "../../utils/Functions"
import { useAppContext } from "../../context/Context"

export default function useManageFeedBacks() {
    const [_, dispatch] = useAppContext()
    const [feedbacks, setFeedbacks] = useState([])

    useEffect(() => {
        loadFeedBacks()
    }, [])

    const loadFeedBacks = async () => {
        try {
            const response = await api.get('/feedback/all')
            setFeedbacks(response.data)
        }
        catch (error) {
            setBottomBarError(dispatch, error)
        }
    }



    return [{
        feedbacks
    }, {
        loadFeedBacks,
        dispatch
    }]
}
